import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./auth/auth.guard";

const routes: Routes = [
  { 
    path: 'auth', 
    loadChildren: () => import('./auth/auth.module').then(x => x.AuthModule), 
    canActivate: [AuthGuard],
  },
  { 
    path: '', 
    loadChildren: () => import('./main/main.module').then(x => x.MainModule), 
    canActivate: [AuthGuard] }
  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ],
})
export class AppRoutingModule { }