
<mat-sidenav-container 
  class="sidebar-container" 
  (window:resize)="onResize($event)">
  <mat-sidenav 
    #sidenav 
    mode="side" 
    [opened]="isOpened" 
    class="sidenav-fixed">
    <app-sidebar></app-sidebar>
  </mat-sidenav>
  <mat-sidenav-content 
    class="d-flex flex-column sidenav-bar">
    <div 
      class="header d-flex justify-content-between 
        align-items-center">
      <button 
        mat-icon-button 
        (click)="sidenav.toggle()">
        <mat-icon>menu</mat-icon>
      </button>
      <app-header></app-header>
    </div>
    <div 
      class="content-container">
      <router-outlet></router-outlet>
    </div>
    <app-footer></app-footer>
  </mat-sidenav-content>
</mat-sidenav-container>
