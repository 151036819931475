import {Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Optional, Output} from '@angular/core';
import {ImageCroppedEvent} from 'ngx-image-cropper';
import {Subject} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {takeUntil} from "rxjs/operators";
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-cropper',
  templateUrl: './cropper.component.html',
})
export class CropperComponent implements OnDestroy {
  @Input() imageRatio!:number;
  @Input() storageFolder!: string;
  private _unsubscribeAll = new Subject();
  croppedImage: any = '';

  @Output() imageURL = new EventEmitter<any>();

  constructor(public dialog: MatDialog) {}

  openDialog(event: any): void {
    const dialogRef
      = this.dialog.open(CropperImageDialogComponent,
      {
        data: {
          event: event,
          aspectRatio: this.imageRatio,
          storageFolder: this.storageFolder,
        },

      }
    );

    dialogRef.afterClosed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(result => {
      if (result.event == 'Upload') {
        this.croppedImage = result.data.image;
        this.imageURL.emit(result.data.file);
      }
    });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}





@Component({
  selector: 'app-cropper-image-dialog',
  templateUrl: 'cropper-image-dialog.html',
})
export class CropperImageDialogComponent implements OnInit {
  authFile: any;
  imageChangedEvent: any = '';
  local_data: any;
  auth! : any;
  croppedImage: any = '';

  storageFolder!: string;
  aspectRatio!: number;

  constructor(
    public dialogRef: MatDialogRef<CropperImageDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.fileChangeEvent(data.event);
    this.storageFolder = data.storageFolder;
    this.aspectRatio = data.aspectRatio;
  }

  ngOnInit(): void {
    this.auth = JSON.parse(localStorage.getItem('currentUser') || '{}');
    this.authFile = this.auth.storageFolder;
  }

  imageCropped(event: ImageCroppedEvent) {
    const today = new Date();
    this.croppedImage = event.base64;
    const imageName = this.storageFolder + '/' + uuidv4() +'.png';
    const imageBlob = this.dataURItoBlob(this.croppedImage);
    const imageFile = new File( [imageBlob], imageName, { type: 'image/png' } );
    this.local_data = { image: this.croppedImage, file: imageFile };
  }

  fileChangeEvent( event: any ): void {
    this.imageChangedEvent = event;
  }

  dataURItoBlob( dataURI: any ) {
    const byteString
      = atob(dataURI
        .replace(/^data:image\/(png|jpeg|jpg);base64,/, ''));
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/png' });
    return blob;
  }

  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  doAction() {
    this.dialogRef.close({
      event: 'Upload',
      data: this.local_data
    });
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }

 }
