<h1
  mat-dialog-title>
  Image Cropper
</h1>
<mat-dialog-content>
  <image-cropper
            [imageChangedEvent]="imageChangedEvent"
            [maintainAspectRatio]="true"
            [aspectRatio]="aspectRatio"
            format="png"
            (imageCropped)="imageCropped($event)"
            (imageLoaded)="imageLoaded()"
            (cropperReady)="cropperReady()"
            (loadImageFailed)="loadImageFailed()"
            style="max-width: 400px; margin: 0 auto; width: 100%"
        ></image-cropper>
</mat-dialog-content>
<mat-dialog-actions
  align="end">
  <button
    mat-button
    (click)="doAction()">
    Upload
  </button>
  <button
    mat-button
    (click)="closeDialog()"
    mat-flat-button
    color="warn">
    Cancel
  </button>
</mat-dialog-actions>
