export class GetTestimonials {
  static readonly type = '[testimonials] get testimonials';
  constructor(public keyword: any, public skip: any) { }
}

export class GetTestimonial {
  static readonly type = '[testimonial] get testimonial';
  constructor(public id: number) { }
}

export class GetTestimonialsCounter {
  static readonly type = '[testimonial] get testimonial counter';
  constructor(public params: any) { }
}

export class CreateTestimonial {
  static readonly type = '[testimonial] create testimonial';
  constructor(public testimonial: any) { }
}

export class DeleteTestimonial {
  static readonly type = '[testimonial] delete testimonial';
  constructor(public id: number) { }
}

export class NewTestimonial {
  static readonly type = '[testimonial] new testimonial';
}

export class RequestSuccess {
  static readonly type = '[testimonials] request success';
  constructor(
    public message: string, 
    public navigate: string = ''
  ) { }
}

export class RequestError {
  static readonly type = '[testimonials] request error';
  constructor(
    public message: string, 
    public navigate: string = ''
  ) { }
}