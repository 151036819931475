import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { MainModule } from './main/main.module';
import { SharedModule } from './shared/shared.module';

import { NgxsModule } from '@ngxs/store';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';

import { environment } from 'src/environments/environment';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './shared/interceptors/auth.interceptor';
import { 
  ReCaptchaV3Service, 
  RECAPTCHA_LANGUAGE, 
  RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';

import { AuthState } from './shared/store/states/auth.state';
import { TestimonialState } from './shared/store/states/testimonial.state';
import { BlogState } from './shared/store/states/blog.state';
import { ProjectState } from './shared/store/states/project.state';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    MainModule,
    SharedModule,
    NgxsModule.forRoot([
      AuthState, 
      TestimonialState, 
      BlogState, 
      ProjectState
    ], {
      developmentMode: !environment.production
    }),
    NgxsRouterPluginModule.forRoot(),
    // NgxsLoggerPluginModule.forRoot({
    //   disabled: environment.production,
    // }),
  ],
  exports: [
    SharedModule
  ],
  providers: [
    ReCaptchaV3Service,
    { 
      provide: HTTP_INTERCEPTORS, 
      useClass: AuthInterceptor, 
      multi: true 
    },
    {
      provide: RECAPTCHA_LANGUAGE,
      useValue: 'en', // use French language
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.RECAPTCHA_key
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
