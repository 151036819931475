import { Injectable } from "@angular/core";
import { 
  State, 
  Selector, 
  StateContext, 
  Action 
} from "@ngxs/store";
import { Navigate } from "@ngxs/router-plugin";

import { 
  GetBlogs, 
  RequestSuccess, 
  RequestError, 
  GetBlog, SaveBlog, 
  DeleteBlog, 
  NewBlog, 
  GetCategories, 
  GetBlogsCounter, 
  GetCategoryCounter 
} from "../actions/blog.action";
import { GlobalService } from "../../services/global.service";
import { BlogService } from "../../services/blog.service";
import { BlogDefaults, Category } from "../../models/blog.model";

export class BlogStateModel {
  blogs?: any;
  current?: any;
  pagination?: {} | any;
  counter?: number | any;
}

export class CategoryStateModel {
  categories?: Category | any;
  categoryCounter: any;
}

@State<BlogStateModel>({
  name: 'blog',
  defaults: {
    blogs: [],
    current: [],
    pagination: [],
    counter: 0
  }
})

@State<CategoryStateModel>({
  name: 'categories',
  defaults: {
    categories: [],
    categoryCounter: []
  }
})

@Injectable()

export class BlogState {
  constructor(
    private _globalService: GlobalService,
    private _blogService: BlogService
  ) { }

  @Selector()
  static blogs(state: BlogStateModel): any[] {
    return state.blogs;
  }

  @Selector()
  static current(state: BlogStateModel): {} {
    return state.current;
  }

  @Selector()
  static pagination(state: BlogStateModel): {} {
    return state.pagination;
  }

  @Selector()
  static counter(state: BlogStateModel): {} {
    return state.counter;
  }

  @Selector()
  static categories(state: CategoryStateModel): {} {
    return state.categories;
  }

  @Selector()
  static categoryCounter(state: CategoryStateModel): {} {
    return state.categoryCounter;
  }

  @Action(GetBlogs, { cancelUncompleted: true })
  getBlogs(ctx: StateContext<BlogStateModel>, 
    { params }: GetBlogs): void {
    this._blogService.adminFindBlogs(params).subscribe(
      (data: any) => {
        if (data && data.error_code.message === 'Success') {
          const pagination = Object.assign({}, data);

          delete pagination.data;
          delete pagination.status;

          ctx.patchState({
            blogs: data.response,
            pagination: pagination,
          });

          localStorage
            .setItem('postLength', data.response.length);

          ctx.dispatch(new RequestSuccess(''));
        } else {

          const error = data.error_code;
          error.code = data.message;

          ctx.dispatch(new RequestError(error.message));
        }
      },
    )
  }

  @Action(GetBlogsCounter, { cancelUncompleted: true })
  getBlogsCounter(ctx: StateContext<BlogStateModel>, 
    { params }: GetBlogsCounter): void {
    this._blogService.adminFindBlogsCounter(params)
      .subscribe(
      (data: any) => {
        if (data && data.error_code.message === 'Success') {

          ctx.patchState({
            counter: data.response
          });

          ctx.dispatch(new RequestSuccess(''));
        } else {

          const error = data.error_code;
          error.code = data.message;

          ctx.dispatch(new RequestError(error.message));
        }
      },
    )
  }

  @Action(GetBlog, { cancelUncompleted: true })
  getBlog(ctx: StateContext<BlogStateModel>, 
    { id }: GetBlog): void {
    this._blogService.adminGetBlog(id)
    .subscribe(
      (data: any) => {
        if (data && data.error_code.message === 'Success') {
          ctx.patchState({
            current: data.response
          });
          ctx.dispatch(
            new RequestSuccess(data.error_code.message 
              + ' Retrieving Post'));
        } else {
          const error = data.error_code;
          ctx.dispatch(new RequestError(error.message));
        }
      },
      (err: any) => {
        const error = err.error;
        error.code = err.status;

        ctx.dispatch(new RequestError(error.message));
      }
    );
  }

  @Action(SaveBlog, { cancelUncompleted: true })
  saveBlog(ctx: StateContext<BlogStateModel>, 
    { post }: SaveBlog): void {
    this._blogService.adminSaveBlog(post).subscribe(
      (data: any) => {
        if (data && data.error_code.message === 'Success') {
          ctx.dispatch(
            new RequestSuccess(data.error_code.message 
              + ' Adding Post', `/blog`));
        } else {
          const error = data.error_code;
          ctx.dispatch(new RequestError(error.message));
        }
      },
      (err: any) => {
        const error = err.error;
        error.code = err.status;

        ctx.dispatch(new RequestError(error.message));
      }
    )
  }

  @Action(DeleteBlog, { cancelUncompleted: true })
  deleteBlog(ctx: StateContext<BlogStateModel>, 
    { id }: DeleteBlog): void {
    this._blogService.adminRemoveBlog(id).subscribe(
      (data: any) => {
        if (data && data.error_code.message === 'Success') {
          ctx.dispatch(
            new RequestSuccess(data.error_code.message));
          location.reload();
        } else {
          const error = data.error_code;
          ctx.dispatch(new RequestError(error.message));
        }
      },
      (err: any) => {
        const error = err.error;
        error.code = err.status;

        ctx.dispatch(new RequestError(error.message));
      }
    );
  }

  @Action(NewBlog)
  newProject({ patchState }: StateContext<BlogStateModel>): void {
    const newBlogDefaults = Object.assign({}, BlogDefaults);

    patchState({
      current: newBlogDefaults
    });

  }

  @Action(GetCategories, { cancelUncompleted: true })
  getCategories(ctx: StateContext<CategoryStateModel>): void {
    this._blogService.getCategories().subscribe(
      (data: any) => {
        if (data && data.error_code.message === 'Success') {
          const pagination = Object.assign({}, data);

          delete pagination.data;
          delete pagination.status;

          ctx.patchState({
            categories: data.response
          });

          ctx.dispatch(new RequestSuccess(''));
        } else {

          const error = data.error_code;
          error.code = data.message;

          ctx.dispatch(new RequestError(error.message));
        }
      },
    )
  }


  @Action(GetCategoryCounter, { cancelUncompleted: true })
  getCategoryCounter(ctx: StateContext<CategoryStateModel>): void {
    this._blogService.adminBlogsCategoryCounter().subscribe(
      (data: any) => {
        if (data && data.error_code.message === 'Success') {
          ctx.patchState({
            categoryCounter: data.response
          });
    
          ctx.dispatch(new RequestSuccess(''));
        } else {

          const error = data.error_code;
          error.code = data.message;

          ctx.dispatch(new RequestError(error.message));
        }
      },
    )
  }

  @Action(RequestSuccess)
  requestSuccess(ctx: StateContext<BlogStateModel>, 
    { message, navigate }: RequestSuccess): void {
    const state = ctx.getState();
    if (message) {
      this._globalService.openSnackBar(message);
    }

    if (navigate) {
      ctx.dispatch(new Navigate([navigate]));
    }
  }

  @Action(RequestError)
  requestError(ctx: StateContext<BlogStateModel>, 
    { message, navigate }: RequestError): void {
    const state = ctx.getState();
    if (message) {
      this._globalService.openSnackBar(message);
    }

    if (navigate) {
      ctx.dispatch(new Navigate([navigate]));
    }
  }
}
