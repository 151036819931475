import { Injectable } from "@angular/core";
import { 
  State, 
  Selector, 
  StateContext, 
  Action 
} from "@ngxs/store";
import { Navigate } from "@ngxs/router-plugin";

import { 
  GetProjects, 
  RequestSuccess, 
  RequestError, 
  GetProject, 
  SaveProject, 
  DeleteProject, 
  NewProject, 
  ChangeOrder, 
  GetCounter 
} from "../actions/project.action";
import { GlobalService } from "../../services/global.service";
import { ProjectService } from "../../services/project.services";
import { 
  Project, 
  ProjectDefaults 
} from "../../models/project.model";

export class ProjectStateModel {
  projects?: Project[] | any;
  current?: Project | any;
  pagination?: {} | any;
  counter?: number | any;
}

@State<ProjectStateModel>({
  name: 'project',
  defaults: {
    projects: [],
    current: ProjectDefaults,
    pagination: {},
    counter: 0
  }
})

@Injectable()

export class ProjectState {
  constructor(
    private _globalService: GlobalService,
    private _projectService: ProjectService
  ) { }

  @Selector()
  static projects(state: ProjectStateModel): any[] {
    return state.projects;
  }

  @Selector()
  static current(state: ProjectStateModel): {} {
    return state.current;
  }

  @Selector()
  static pagination(state: ProjectStateModel): {} {
    return state.pagination;
  }

  @Selector()
  static counter(state: ProjectStateModel): {} {
    return state.counter;
  }


  @Action(GetProjects, { cancelUncompleted: true })
  getProjects(ctx: StateContext<ProjectStateModel>, 
    { keyword, skip }: GetProjects): void {
    const filter = { keyword, skip };
    this._projectService.adminFindPortfolios(filter)
      .subscribe(
      (data: any) => {
        if (data && data.error_code.message === 'Success') {
          const pagination = Object.assign({}, data);

          delete pagination.response;
          delete pagination.error_code;

          ctx.patchState({
            projects: data.response,
          });

          ctx.dispatch(new RequestSuccess(''));
        } else {
          const error = data.error_code;
          ctx.dispatch(new RequestError(error.message));
        }
      },
    )
  }

  @Action(GetProject, { cancelUncompleted: true })
  getProject(ctx: StateContext<ProjectStateModel>, 
    { id }: GetProject): void {
    this._projectService.adminGetPortfolio(id)
    .subscribe(
      (data: any) => {
        if (data && data.error_code.message === 'Success') {
          ctx.patchState({
            current: data.response
          });
          ctx.dispatch(
            new RequestSuccess(data.error_code.message 
              + ' Retrieving Project'));
        } else {
          const error = data.error_code;
          ctx.dispatch(new RequestError(error.message));
        }
      },
      (err: any) => {
        const error = err.error;
        error.code = err.status;

        ctx.dispatch(new RequestError(error.message));
      }
    );
  }

  @Action(GetCounter, { cancelUncompleted: true })
  getCounter(ctx: StateContext<ProjectStateModel>, 
    { params }: GetCounter): void {
    this._projectService.adminFindPortfoliosCounter(params)
    .subscribe(
      (data: any) => {
        if (data && data.error_code.message === 'Success') {

          ctx.patchState({
            counter: data.response,
          });

          ctx.dispatch(new RequestSuccess(''));
        } else {
          const error = data.error_code;
          ctx.dispatch(new RequestError(error.message));
        }
      },
    );
  }

  @Action(SaveProject, { cancelUncompleted: true })
  saveProject(ctx: StateContext<ProjectStateModel>, 
    { project }: SaveProject): void {
    this._projectService.adminSavePortfolio(project).subscribe(
      (data: any) => {
        if (data && data.error_code.message === 'Success') {
          ctx.dispatch(
            new RequestSuccess(data.error_code.message 
              + ' Adding Project', `/projects`));
        } else {
          const error = data.error_code;
          ctx.dispatch(new RequestError(error.message));
        }
      },
      (err: any) => {
        const error = err.error;
        error.code = err.status;

        ctx.dispatch(new RequestError(error.message));
      }
    )
  }

  @Action(DeleteProject, { cancelUncompleted: true })
  deleteProject(ctx: StateContext<ProjectStateModel>, 
    { id }: DeleteProject): void {
    this._projectService.adminRemovePortfolio(id)
      .subscribe(
      (data: any) => {
        if (data && data.error_code.message === 'Success') {
          ctx.dispatch(
            new RequestSuccess(data.error_code.message));
          location.reload();
        } else {
          const error = data.error_code;
          ctx.dispatch(new RequestError(error.message));
        }
      },
      (err: any) => {
        const error = err.error;
        error.code = err.status;

        ctx.dispatch(new RequestError(error.message));
      }
    );
  }

  @Action(NewProject)
  newProject({ patchState }: 
    StateContext<ProjectStateModel>): void {
    const newProjectDefaults = Object.assign({}, ProjectDefaults);

    patchState({
      current: newProjectDefaults
    });

  }

  @Action(ChangeOrder, { cancelUncompleted: true })
  changeOrder(ctx: StateContext<ProjectStateModel>, 
    { order }: ChangeOrder): void {
    this._projectService.adminChangeOrder(order)
    .subscribe(
      (data: any) => {
        if (data && data.error_code.message === 'Success') {
          const keyword = '';
          const skip = 1;
          const filter = { keyword, skip };
          this._projectService.adminFindPortfolios(filter)
            .subscribe(
            (data: any) => {
              ctx.patchState({
                projects: data.response,
              });
            }
          )
          ctx.dispatch(
            new RequestSuccess(data.error_code.message 
              + 'Order Changed'));
        } else {
          const error = data.error_code;
          ctx.dispatch(new RequestError(error.message));
        }
      },
      (err: any) => {
        const error = err.error;
        error.code = err.status;

        ctx.dispatch(new RequestError(error.message));
      }
    )
  }

  @Action(RequestSuccess)
  requestSuccess(ctx: StateContext<ProjectStateModel>, 
    { message, navigate }: RequestSuccess): void {
    const state = ctx.getState();
    if (message) {
      this._globalService.openSnackBar(message);
    }

    if (navigate) {
      ctx.dispatch(new Navigate([navigate]));
    }
  }

  @Action(RequestError)
  requestError(ctx: StateContext<ProjectStateModel>, 
    { message, navigate }: RequestError): void {
    const state = ctx.getState();
    if (message) {
      this._globalService.openSnackBar(message);
    }

    if (navigate) {
      ctx.dispatch(new Navigate([navigate]));
    }
  }
}
