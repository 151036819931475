export class GetProjects {
  static readonly type = '[projects] get projects';
  constructor(public keyword: any, public skip: any) { }
}

export class GetProject {
  static readonly type = '[project] get project';
  constructor(public id: number) { }
}

export class GetCounter {
  static readonly type = '[project] get counter';
  constructor(public params: any) { }
}

export class SaveProject {
  static readonly type = '[project] create project';
  constructor(public project: any) { }
}

export class DeleteProject {
  static readonly type = '[project] delete project';
  constructor(public id: number) { }
}

export class NewProject {
  static readonly type = '[project] new project';
}

export class ChangeOrder {
  static readonly type = '[project] order change project';
  constructor(public order: any) { }
}

export class RequestSuccess {
  static readonly type = '[projects] request success';
  constructor(
    public message: string, 
    public navigate: string = ''
  ) { }
}

export class RequestError {
  static readonly type = '[projects] request error';
  constructor(
    public message: string, 
    public navigate: string = ''
  ) { }
}