import { Injectable } from "@angular/core";
import { 
  State, 
  Selector, 
  StateContext, 
  Action 
} from "@ngxs/store";
import { Navigate } from "@ngxs/router-plugin";
import { 
  GetTestimonials, 
  RequestSuccess, 
  RequestError, 
  CreateTestimonial, 
  GetTestimonial, 
  DeleteTestimonial, 
  NewTestimonial, 
  GetTestimonialsCounter 
} from "../actions/testimonials.action";
import { GlobalService } from "../../services/global.service";
import { 
  TestimonialsService 
} from "../../services/testimonials.service";
import { 
  Testimonial, 
  TestimonialDefaults 
} from "../../models/testimonial.model";

export class TestimonialStateModel {
  testimonials?: Testimonial[] | any;
  current?: Testimonial | any;
  pagination?: {} | any;
  counter?: number | any;
}

@State<TestimonialStateModel>({
  name: 'testimonial',
  defaults: {
    testimonials: [],
    current: TestimonialDefaults,
    pagination: {},
    counter: 0
  }
})

@Injectable()

export class TestimonialState {
  constructor (
    private _globalService: GlobalService,
    private _testimonialsService: TestimonialsService
  ){}

  @Selector()
  static testimonials(state: TestimonialStateModel): any[] {
    return state.testimonials;
  }

  @Selector()
  static current(state: TestimonialStateModel): {} {
    return state.current;
  }

  @Selector()
  static pagination(state: TestimonialStateModel): {} {
    return state.pagination;
  }

  @Selector()
  static counter(state: TestimonialStateModel): {} {
    return state.counter;
  }

  @Action(GetTestimonials, {cancelUncompleted: true})
  getTestimonials(ctx: StateContext<TestimonialStateModel>, 
    { keyword, skip }: GetTestimonials ): void {
    const filter = { keyword, skip };
    this._testimonialsService.findObjects( filter )
      .subscribe(
      (data: any) => {
        if (data && data.error_code.message === 'Success'){
          const pagination = Object.assign({}, data);

          delete pagination.response;
          delete pagination.error_code;

          ctx.patchState({
            testimonials: data.response,
            pagination: pagination
          });
          
          ctx.dispatch(new RequestSuccess(''));
        } else {
          const error = data.error_code;
          ctx.dispatch(new RequestError(error.message));
        }
      },
    )
  }

  @Action(GetTestimonialsCounter, { cancelUncompleted: true })
  getTestimonialsCounter(ctx: StateContext<TestimonialStateModel>, 
    { params }: GetTestimonialsCounter): void {
    this._testimonialsService.findObjectsCounter(params)
    .subscribe(
      (data: any) => {
        if (data && data.error_code.message === 'Success') {
          ctx.patchState({
            counter: data.response
          });

          ctx.dispatch(new RequestSuccess(''));
        } else {
          const error = data.error_code;
          ctx.dispatch(new RequestError(error.message));
        }
      },
    )
  }

  @Action(GetTestimonial, { cancelUncompleted: true })
  getTestimonial(ctx : StateContext<TestimonialStateModel>, 
    { id }: GetTestimonial): void {
    this._testimonialsService.getObject(id).subscribe(
      (data: any) => {
        if (data && data.error_code.message === 'Success') {
          ctx.patchState({
            current: data.response
          });
          ctx.dispatch(
            new RequestSuccess(data.error_code.message 
              + ' Retrieving Testimonial'));
        } else {
          const error = data.error_code;
          ctx.dispatch(new RequestError(error.message));
        }
      },
      (err: any) => {
        const error = err.error;
        error.code = err.status;

        ctx.dispatch(new RequestError(error.message));
      }
    );
  }

  @Action(CreateTestimonial, { cancelUncompleted: true})
  createTestimonial(ctx: StateContext<TestimonialStateModel>, 
    { testimonial } : CreateTestimonial): void{
    this._testimonialsService.saveObject(testimonial)
    .subscribe(
      (data: any) => {
        if (data && data.error_code.message === 'Success') {
          ctx.dispatch(
            new RequestSuccess(data.error_code.message 
              + ' Adding Testimonial', `/testimonials`));
        } else {
          const error = data.error_code;
          ctx.dispatch(new RequestError(error.message));
        }
      },
      (err: any) => {
        const error = err.error;
        error.code = err.status;

        ctx.dispatch(new RequestError(error.message));
      }
    )
  }

  @Action(DeleteTestimonial, { cancelUncompleted: true })
  deleteTestimonial(ctx: StateContext<TestimonialStateModel>, 
    { id }: DeleteTestimonial): void {
    this._testimonialsService.deleteObject(id, '')
    .subscribe(
      (data: any) => {
        if (data && data.error_code.message === 'Success') {
          ctx.dispatch(
            new RequestSuccess(data.error_code.message));
          location.reload();
        } else {
          const error = data.error_code;
          ctx.dispatch(new RequestError(error.message));
        }
      },
      (err: any) => {
        const error = err.error;
        error.code = err.status;

        ctx.dispatch(new RequestError(error.message));
      }
    );
  }

  @Action(NewTestimonial)
  newTestimonial({ patchState }: 
    StateContext<TestimonialStateModel>): void {
    const newTestimonialDefaults 
      = Object.assign({}, TestimonialDefaults);

    patchState({
      current: newTestimonialDefaults
    });

  }

  @Action(RequestSuccess)
  requestSuccess({ getState, dispatch }: 
    StateContext<TestimonialStateModel>, 
    { message, navigate }: RequestSuccess): void {
    const state = getState();
    if (message) {
      this._globalService.openSnackBar(message);
    }

    if (navigate) {
      dispatch(new Navigate([navigate]));
    }
  }

  @Action(RequestError)
  requestError({ getState, dispatch }: 
    StateContext<TestimonialStateModel>, 
    { message, navigate }: RequestError): void {
    const state = getState();
    if (message) {
      this._globalService.openSnackBar(message);
    }

    if (navigate) {
      dispatch(new Navigate([navigate]));
    }
  }
}
