import { v4 as uuidv4 } from 'uuid';

export interface Project {
  id: number | null;
  updatedAt: number,
  createdAt: number,
  publishedAt: number;
  publishURLPath: string;
  status: boolean;
  title: string;
  project: string;
  excerpt: string;
  contentRight: string;
  imageURL: string;
  folder: string;
  content: string;
  industry: string;
  technologies: string | null;
  backgroundGradientColors: string;
  duration: number;
  userID: number;
  caseStudy: boolean;
  order: number;
  homepage: boolean;
  software: boolean;
  node: boolean;
  slug: string;
  seoMetatitle: string;
  seoDescription: string;
  seoKeywords: string;
  seoImageURL: string;
}

export const ProjectDefaults: Project = {
  id: null,
  updatedAt: 0,
  createdAt: 0,
  publishedAt: 0,
  publishURLPath: '',
  status: false,
  title: '',
  project: '',
  excerpt: '',
  contentRight: '',
  imageURL: '',
  folder: uuidv4(),
  content: '',
  industry: '',
  technologies: null,
  backgroundGradientColors: '',
  duration: 0,
  userID: 0,
  caseStudy: false,
  order: 0,
  homepage: false,
  software: false,
  node: false,
  slug: '',
  seoMetatitle: '',
  seoDescription: '',
  seoKeywords: '',
  seoImageURL: ''
}

export enum ProjectIndustry {
  'finance-fintech' = 'Finance & Fintech',
  'automotive' = 'Automotive',
  'telecommunications' = 'Telecommunications',
  'healthcare' = 'Healthcare',
  'gaming-entertainment' = 'Gaming & Entertainment',
  'retail-ecommerce' = 'Retail & E-commerce',
  'business-productivity' = 'Business & Productivity',
  'chemical-industry' = 'Chemical Industry',
  'location-tracking' = 'Location & Tracking',
  'event-management' = 'Event Management',
  'beauty-personal-care' = 'Beauty & Personal Care',
}











