import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-full-page',
  templateUrl: './full-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FullPageComponent {

  constructor() { }
  
}
