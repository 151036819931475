import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { SidebarPageComponent } from "../shared/layout/sidebar-page/sidebar-page.component";
import { ErrorComponent } from "./error/error.component";

const routes: Routes = [
  {
    path: '',
    component: SidebarPageComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./dashboard/dashboard.module').then(x => x.DashboardModule),
        data: {
          title: 'Dashboard',
        },
      },
      // {
      //   path: 'testimonials',
      //   loadChildren: () => import('./testimonials/testimonials.module').then(x => x.TestimonialsModule),
      //   data: {
      //     title: 'Testimonials',
      //   },
      // },
      {
        path: 'projects',
        loadChildren: () => import('./projects/projects.module').then(x => x.ProjectsModule),
        data: {
          title: 'Projects',
        },
      },

      // {
      //   path: 'blog',
      //   loadChildren: () => import('./blog/blog.module').then(x => x.BlogModule),
      //   data: {
      //     title: 'Blogs',
      //   },
      // },

      // {
      //   path: 'account-settings',
      //   loadChildren: () => import('./account-settings/account-settings.module').then(x => x.AccountSettingsModule),
      //   data: {
      //     title: 'Account Settings',
      //   },
      // },

      {
        path: '**',
        pathMatch: 'full',
        component: ErrorComponent,
        data: {
          title: 'Page Not Found',
        },
      },
    ]
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ],
})
export class MainRoutingModule { }
