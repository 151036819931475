import { 
  ChangeDetectionStrategy, 
  Component, 
  HostListener
} from '@angular/core';

@Component({
  selector: 'app-sidebar-page',
  templateUrl: './sidebar-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarPageComponent {
  public innerWidth: any;
  isOpened: boolean = true;

  constructor() { }

  @HostListener('window:resize', ['$event'])

  onResize(event) {
    this.innerWidth = window.innerWidth;
    if(this.innerWidth <= 750){
      this.isOpened = false;
    } else {
      this.isOpened = true;
    }
  }
}
