export interface Testimonial {
  id?: number | null;
  sourceURL?: string | null;
  source?: string | null;
  name?: string | null;
  src?: string | null;
  location?: string | null;
  title?: string | null;
  company?: string | null;
  description?: string | null; 
  rank?: number | null;
  folder?: string | null 
}

export const TestimonialDefaults: Testimonial = {
  id: null,
  sourceURL: '',
  source: 'main',
  name: '',
  src: '',
  location: '',
  title: '',
  company: '',
  description: '',
  rank: 1,
  folder: ''
}