import { Injectable } from '@angular/core';
import { 
  State, 
  Selector, 
  StateContext, 
  Action, 
  NgxsOnInit 
} from '@ngxs/store';
import { Navigate } from '@ngxs/router-plugin';
import { 
  LoginAuth, 
  LogoutAuth, 
  CheckAuth, 
  DeleteAuth, 
  RequestSuccess, 
  RequestError 
} from '../actions/auth.actions';
import { AuthService } from '../../services/auth.service';
import { GlobalService } from '../../services/global.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { User } from '../../models/user.model';

export class AuthStateModel {
  auth: User | any;
  token?: string | any;
  logged?: boolean;
}

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    auth: null,
    token: null,
    logged: false
  }
})

@Injectable()
export class AuthState implements NgxsOnInit {
  constructor(
    private _globalService: GlobalService,
    private _authService: AuthService,
    protected recaptchaV3Service: ReCaptchaV3Service
  ) { }

  @Selector()
  static auth(state: AuthStateModel): any {
    return state.auth;
  }

  @Selector()
  static token(state: AuthStateModel): string {
    return state.token;
  }

  @Selector()
  static logged(state: AuthStateModel): boolean {
    return state.logged!;
  }

  ngxsOnInit(ctx: StateContext<AuthStateModel>): void {
    ctx.dispatch(new CheckAuth());
  }

  @Action(LoginAuth, { cancelUncompleted: true })
  loginAuth(ctx: StateContext<AuthStateModel>
    , { auth }: LoginAuth): void {
    this.recaptchaV3Service.execute('importantAction')
      .subscribe((token: string | any) => {
        const formData = auth;
        formData.captcha = token;
        this._authService.login(formData)
          .subscribe(
            (data: any) => {
              if (data.response) {
                ctx.patchState({ logged: true });
                setTimeout(() => {
                  ctx.dispatch(
                    new RequestSuccess("Login Successfully", '/')
                  );
                },1000);
              }
            },
            (response: any) => {
              if (response && response.error) {
                const data = response.error;

                ctx.dispatch(
                  new RequestError(data.error.message 
                    + ": Invalid Username or Password"));
              }
            },
          );
      });
  }

  @Action(LogoutAuth, { cancelUncompleted: true })
  logoutAuth(ctx: StateContext<AuthStateModel>): void {
    this._authService.logout().subscribe();
    ctx.dispatch(new DeleteAuth());
    ctx.dispatch(new Navigate(['/auth/login']));
  }

  @Action(DeleteAuth, { cancelUncompleted: true })
  deleteAuth(ctx: StateContext<AuthStateModel>): void {
    localStorage.clear();

    ctx.dispatch(new CheckAuth());
  }

  @Action(CheckAuth, { cancelUncompleted: true })
  checkAuth(ctx: StateContext<AuthStateModel>): void {
    const token = localStorage.getItem('token');

    if (token) {
      const auth = JSON
        .parse(localStorage
            .getItem('currentUser') || '{}');

      ctx.patchState({
        token, auth, logged: true
      });
    } else {
      ctx.patchState({
        token: null, auth: null, logged: false
      });
    }
  }

  @Action(RequestSuccess)
  requestSuccess(ctx: StateContext<AuthStateModel>, 
    { message, navigate }: RequestSuccess): void {
    const state = ctx.getState();
    if (message) {
      this._globalService.openSnackBar(message);
    }

    if (navigate) {
      ctx.dispatch(new Navigate([navigate]));
    }
  }

  @Action(RequestError)
  requestError(ctx: StateContext<AuthStateModel>, 
    { message, navigate }: RequestError): void {
    const state = ctx.getState();
    if (message) {
      this._globalService.openSnackBar(message);
    }

    if (navigate) {
      ctx.dispatch(new Navigate([navigate]));
    }
  }
}

