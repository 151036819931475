import {
  Inject,
  Injectable,
  PLATFORM_ID
} from '@angular/core';

import { RequestService } from './request.service';
import { map, Observable } from 'rxjs';

import { isPlatformBrowser } from '@angular/common';
import { User } from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private _requestService: RequestService,
    @Inject(PLATFORM_ID) private readonly platformId: any
  ) { }

  login(data: {}): Observable<User> {
    return this._requestService
      .post('api/v1/clientusers/adminLogin', data)
      .pipe(
        map(
          (res: any) => {
            localStorage
              .setItem('currentUser',
                JSON.stringify(res.response.user));
            localStorage
              .setItem('token',
                JSON.stringify(res.response.id));
            localStorage
              .setItem('ttl',
                JSON.stringify(res.response.ttl));
            return res;
          }
        )
      );
  }

  logout() {
    return this._requestService
      .post('api/v1/clientusers/clientLogout', {})
  }

  getToken(): any {
    if (localStorage.getItem('token')) {
      return JSON.parse(
        localStorage
          .getItem('token') || '{}');
    } else {
      return '';
    }
  }

  adminAuthenticated(): boolean {
    if (isPlatformBrowser(this.platformId)) {
      return JSON
        .parse(
            localStorage
              .getItem('currentUser') || '{}') &&
        JSON
          .parse(
              localStorage
                .getItem('currentUser') || '{}').role === 'admin';
    } else {
      return false;
    }

  }

}
