export class GetBlogs {
  static readonly type = '[blogs] get blogs';
  constructor(public params: any) { }
}

export class GetBlog {
  static readonly type = '[blog] get blog';
  constructor(public id: number) { }
}

export class GetBlogsCounter {
  static readonly type = '[blogs] get blogs counter';
  constructor(public params: any) { }
}

export class SaveBlog {
  static readonly type = '[blog] create blog';
  constructor(public post: any) { }
}

export class DeleteBlog {
  static readonly type = '[blog] delete blog';
  constructor(public id: number) { }
}

export class NewBlog {
  static readonly type = '[blog] new blog';
}

export class GetCategories {
  static readonly type = '[blog] get categories'
}

export class GetCategoryCounter {
  static readonly type = '[blog] get categories counter'
}

export class RequestSuccess {
  static readonly type = '[blogs] request success';
  constructor(
    public message: string, 
    public navigate: string = '') 
  { }
}

export class RequestError {
  static readonly type = '[blogs] request error';
  constructor(
    public message: string, 
    public navigate: string = '') 
  { }
}