<div class="d-flex justify-content-end align-items-center profile">
  <!-- <button 
    mat-flat-button
    [matMenuTriggerFor]="menu"
    #condMenu="matMenuTrigger" 
  >
    <img [src]="avatar" *ngIf="avatar" class="widget-icon">
    <span *ngIf="!avatar" class="widget-icon rounded-circle bg-secondary text-white d-none d-md-inline-block">{{ user.slice(0,1).toUpperCase() }}</span>
    {{ user }}
    <mat-icon class="material-icons-outlined" *ngIf="!condMenu.menuOpen">
      expand_more
    </mat-icon>
    <mat-icon class="material-icons-outlined" *ngIf="condMenu.menuOpen">
      expand_less
    </mat-icon>
  </button>
  <mat-menu #menu="matMenu" xPosition="before">
    <button mat-menu-item>Profile</button>
    <button mat-menu-item (click)="logout()">Logout</button>
  </mat-menu> -->
  <span class="username">{{ user }}</span>
  <a (click)="logout()" class="logout d-flex align-items-center">Logout <mat-icon class="material-icons-outlined">logout</mat-icon> </a>
</div>
