import { Component, OnInit, EventEmitter, Output, ChangeDetectionStrategy } from '@angular/core';
import { Store } from '@ngxs/store';
import { LogoutAuth } from 'src/app/shared/store/actions/auth.actions';
import { AuthState } from 'src/app/shared/store/states/auth.state';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit {
  user: any;
  avatar: any;

  constructor(
    private _store: Store
  ) { }

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('currentUser') || '{}').username;
    this.avatar = JSON.parse(localStorage.getItem('currentUser') || '{}').avatarURL;
  }

  logout(): void {
    this._store.dispatch(new LogoutAuth());
  }

}
