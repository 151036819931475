import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { Testimonial } from '../models/testimonial.model';
import { RequestService } from './request.service';

@Injectable({
  providedIn: 'root'
})

export class TestimonialsService {
  
  
  constructor(
    private _requestService: RequestService
  ) { }

  findObjects(params: any = []): 
    Observable<Testimonial> {
    return this._requestService
      .get('api/v1/testimonials/adminFindObjects', params)
        .pipe(
          map(
            (res: any) => res
          )
        );
  }

  findObjectsCounter(params: any = []): 
    Observable<Testimonial> {
    return this._requestService
      .get('api/v1/testimonials/adminFindObjectsCounter', params)
        .pipe(
          map(
            (res: any) => res
          )
        );
  }

  getObject(id: number): 
    Observable<Testimonial> {
    return this._requestService
      .get('api/v1/testimonials/adminGetObject?id=' + id);
  }


  saveObject(data: {}): 
    Observable<Testimonial> {
    return this._requestService
      .post('api/v1/testimonials/adminSaveObject', data);
  }

  deleteObject(id: number, data: {}): 
    Observable<Testimonial> {
    return this._requestService
      .post('api/v1/testimonials/adminDeleteObject?id=' + id, data);
  }
}
