import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import { AuthService } from '../shared/services/auth.service';
import { AuthState } from '../shared/store/states/auth.state';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

  constructor(private router: Router,
    private authService: AuthService,
    private _store: Store) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (state.url.indexOf('auth') > -1) {
      if (!this.authService.adminAuthenticated()) {
        return true;
      }
      // not logged in so redirect to login page with the return url
      this.router.navigate(['/']);
      return false;
    } else {
      if (this.authService.adminAuthenticated()) {
        return true;
      }

      // not logged in so redirect to login page with the return url
      this.router.navigate(['/auth/login']);
      return false;
    }

  }


}
