import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { Blog, CategoriesCounter, Category } from '../models/blog.model';
import { RequestService } from './request.service';

@Injectable({
  providedIn: 'root'
})

export class BlogService {


  constructor(
    private _requestService: RequestService
  ) { }

  getCategories (): 
    Observable<Category> {
    return this._requestService
      .get('api/v1/postCategories/getCategories');
  }

  adminFindBlogs(params: any = []): 
    Observable<Blog> {
    return this._requestService
      .get('api/v1/blogs/adminFindBlogs' + params)
      .pipe(
        map(
          (res: any) => res
        )
      );
  }

  adminFindBlogsCounter(params: any = []): 
    Observable<Blog> {
    return this._requestService
      .get('api/v1/blogs/adminFindBlogsCounter', params)
      .pipe(
        map(
          (res: any) => res
        )
      );
  }

  adminGetBlog(id: number): 
    Observable<Blog> {
    return this._requestService
      .get('api/v1/blogs/adminGetBlog?id=' + id);
  }

  adminSaveBlog(data: {}): 
    Observable<Blog> {
    return this._requestService
      .post('api/v1/blogs/adminSaveBlog', data);
  }

  adminRemoveBlog(id: number): 
    Observable<Blog> {
    return this._requestService
      .get('api/v1/blogs/adminRemoveBlog?id=' + id);
  }

  adminBlogsCategoryCounter(): 
    Observable<CategoriesCounter> {
    return this._requestService
      .get('api/v1/blogs/adminBlogsCategoryCounter')
        .pipe(
          map(
            (res: any) => res
          )
        );
  }
}
