export interface Blog {
  id?: number | null;
  updatedAt?: number | null;
  createdAt?: number | null;
  publishedAt?: number | null;
  publishURLPath?: string | null;
  status?: boolean | null;
  title?: string | null;
  imageURL?: string | null;
  imageURLCard?: string | null;
  folder?: string | null;
  content?: string | null;
  excerpt?: string | null;
  duration?: number | null;
  userID?: number | null;
  slug?: string | null;
  seoMetatitle?: string | null;
  seoDescription?: string | null;
  seoKeywords?: string | null;
  seoImageURL?: string | null;
  clickToAction?: number | null;
  categories?: string| null;
}

export interface Category {
  id?: number | null;
  title?: string | null;
  updatedAt?: number | null;
  createdAt?: number | null;
}

export interface CategoriesCounter{
  title?: string | null
  number?: number | null
}

export const BlogDefaults: Blog = {
  id: null,
  publishURLPath: "",
  status: false,
  title: "",
  imageURL: "",
  imageURLCard: "",
  folder: "",
  content: "",
  excerpt: "",
  duration: 0,
  userID: 0,
  slug: "",
  seoMetatitle: "",
  seoDescription: "",
  seoKeywords: "",
  seoImageURL: "",
  clickToAction: 0,
  categories: ""
}