import { 
  ChangeDetectionStrategy, 
  Component
} from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent {


  year: any =  new Date().getFullYear();
}
