<div
  class="file-upload">
    <div
      class="form-group files"
      [ngClass]="{'col-md-12': croppedImage}">
      <form>
          <label
            for="image-upload"
            class="file-upload__label d-flex flex-column">
            <img
              [src]="croppedImage"
              class="cropped-image mb-2"
              [ngClass]="{'hidden': !croppedImage}"/>
            <div
              class="d-flex flex-column align-items-center"
              [ngClass]="{'hidden': croppedImage}">
              <strong>Drag & Drop</strong>
              <span
                class="or">
                or
              </span>
              <span
                class="btn btn-secondary">
                Choose File
              </span>
            </div>
            <span
              [ngClass]="{'hidden': !croppedImage}">
              Drag & Drop or Choose another file
            </span>
          </label>
          <input
            type="file"
            class="form-control col-12 col-md-6"
            (change)="openDialog($event)"
            id="image-upload"/>
        </form>
        <p
          class="pt-2 px-3 text-secondary">
          <small>
            *Note: Upload Image and Save to Replace Image
          </small>
        </p>
    </div>
  </div>
