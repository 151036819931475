import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { Project } from '../models/project.model';
import { RequestService } from './request.service';

@Injectable({
  providedIn: 'root'
})

export class ProjectService {


  constructor(
    private _requestService: RequestService
  ) { }

  adminFindPortfolios(params: any = []): 
    Observable<Project> {
    return this._requestService
      .get('api/v1/portfolios/adminFindPortfolios' , params)
      .pipe(
        map(
          (res: any) => res
        )
      );
  }
  adminFindPortfoliosCounter(params: any = []): 
    Observable<Project> {
    return this._requestService.
      get('api/v1/portfolios/adminFindPortfoliosCounter', params);
  }

  adminGetPortfolio(id: number): 
    Observable<Project> {
    return this._requestService
      .get('api/v1/portfolios/adminGetPortfolio?id=' + id);
  }

  adminSavePortfolio(data: {}): 
    Observable<Project> {
    return this._requestService
      .post('api/v1/portfolios/adminSavePortfolio', data);
  }

  adminRemovePortfolio(id: number): 
    Observable<Project> {
    return this._requestService
      .get('api/v1/portfolios/adminRemovePortfolio?id=' + id);
  }

  adminChangeOrder(data: {}): 
    Observable<Project> {
    return this._requestService
      .post('api/v1/portfolios/adminChangeOrder', data)
        .pipe(
          map(
            (res: any) => res
          )
        );
  }
}
