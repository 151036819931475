import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from './material.module';
import { FullPageComponent } from './layout/full-page/full-page.component';
import { RouterModule } from '@angular/router';
import { SidebarPageComponent } from './layout/sidebar-page/sidebar-page.component';
import { HeaderComponent } from './layout/templates/header/header.component';
import { FooterComponent } from './layout/templates/footer/footer.component';
import { SidebarComponent } from './layout/templates/sidebar/sidebar.component';
import { NgxEditorModule } from 'ngx-editor';
import { ReactiveFormsModule } from '@angular/forms';
import { ImageCropperModule } from 'ngx-image-cropper';
import { CropperComponent, CropperImageDialogComponent } from './upload-image/cropper/cropper.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

@NgModule({
  declarations: [
    FullPageComponent,
    SidebarPageComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    CropperComponent,
    CropperImageDialogComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    NgxEditorModule,
    ReactiveFormsModule,
    ImageCropperModule,
    CKEditorModule
  ],
  exports: [
    CropperComponent,
    CropperImageDialogComponent,
    MaterialModule,
    NgxEditorModule,
    ReactiveFormsModule,
    ImageCropperModule,
    CKEditorModule
  ]
})
export class SharedModule { }
