import { Injectable } from '@angular/core';

import {
    MatSnackBar,
    MatSnackBarHorizontalPosition,
    MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root'
})
export class GlobalService {

    private _horizontalPosition: 
        MatSnackBarHorizontalPosition = 'start';
    private _verticalPosition: 
        MatSnackBarVerticalPosition = 'top';

    constructor(
        private _snackBar: MatSnackBar
    ) { }

    openSnackBar(message: any): void {
        this._snackBar.open(message, 'Close', {
            duration: 500,
            horizontalPosition: this._horizontalPosition,
            verticalPosition: this._verticalPosition,
        });
    }

}
