import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest } from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { Store } from '@ngxs/store';
import { DeleteAuth } from '../store/actions/auth.actions';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    public auth: AuthService,
    private _store: Store,
    private _router: Router
  ) {
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let serverReq: HttpRequest<any> = request;
    if (request) {
      const token = this.auth.getToken();
      if (token) {
        if (request.url.indexOf('.s3.') === -1) {
          Object.assign(request, {
            setHeaders: {
              Authorization: token
            }
          });
        }
      }
      serverReq = request.clone(request);
    }

    return next.handle(serverReq).pipe( tap(()=> {},
      (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status !== 401) {
            return;
          }
          this.auth.logout();
          this._store.dispatch(new DeleteAuth());
          this._router.navigate(['/auth/login']);
        }
      }
    ));
  }
}

